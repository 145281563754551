﻿.table {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    & > .row {
        width: 100%;
    }
}
.row, 
.cell {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

    .table.fixed {
        & > .row {
            margin-left: auto;
            margin-right: auto;
        }
    }

.row {
    margin: 0 0 20px 0;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    & > .cell {
        &:last-of-type {
            padding-right: 0;
        }
        &:first-of-type {
            padding-left: 0;
        }
    }
}

.cell {
    display: block;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
    min-height: 1px;
}

/* Opt-in outside padding */
.row-pad {
    padding: 20px 0 20px 20px;
    .cell {
        &:last-of-type {
            padding-right: 20px;
        }
    }
}
.span-1-1 {
    width: 100%;
}

.span-1-2 {
    width: 50%;
}

.span-1-3 {
    width: 33.33%;
}

.span-1-4 {
    width: 25%;
}

.span-1-5 {
    width: 20%;
}

.span-1-6 {
    width: 16.66%;
}

.span-1-7 {
    width: 14.28%;
}

.span-1-8 {
    width: 12.5%;
}

.span-1-9 {
    width: 11.11%;
}

.span-1-10 {
    width: 10%;
}

.span-1-11 {
    width: 9.09%;
}

.span-1-12 {
    width: 8.33%;
}

.span-2-3 {
    width: 66.66%;
}

.span-2-4 {
    width: 50%;
}

.span-2-5 {
    width: 40%;
}

.span-2-6 {
    width: 33.33%;
}

.span-2-8 {
    width: 25%;
}

.span-2-10 {
    width: 20%;
}

.span-2-12 {
    width: 16.66%;
}

.span-3-4 {
    width: 75%;
}

.span-3-5 {
    width: 60%;
}

.span-3-6 {
    width: 50%;
}

.span-3-8 {
    width: 37.5%;
}

.span-3-10 {
    width: 33.33%;
}

.span-3-12 {
    width: 25%;
}

.span-4-5 {
    width: 80%;
}

.span-4-6 {
    width: 66.66%;
}

.span-4-8 {
    width: 50%;
}

.span-4-10 {
    width: 40%;
}

.span-4-12 {
    width: 33.33%;
}

.span-5-6 {
    width: 83.33%;
}

.span-5-8 {
    width: 75%;
}

.span-5-10 {
    width: 50%;
}

.span-5-12 {
    width: 41.66%;
}

.span-6-8 {
    width: 75%;
}

.span-6-10 {
    width: 60%;
}

.span-6-12 {
    width: 50%;
}

.span-7-8 {
    width: 87.5%;
}

.span-7-10 {
    width: 70%;
}

.span-7-12 {
    width: 58.33%;
}

.span-8-10 {
    width: 80%;
}

.span-8-12 {
    width: 66.66%;
}

.span-9-10 {
    width: 90%;
}

.span-9-12 {
    width: 75%;
}

.span-10-12 {
    width: 83.33%;
}

.span-11-12 {
    width: 91.66%;
}

.layout-content-image, 
.layout-content-vector {
    display: block;
    max-width: 100%;
    height: auto;
}

/* Bootstrap compatible spans */
.span-1 { width: 8.33%; }
.span-2 { width: 16.66%; }
.span-3 { width: 25%; }
.span-4 { width: 33.33%; }
.span-5 { width: 41.66%; }
.span-6 { width: 50%; }
.span-7 { width: 58.33%; }
.span-8 { width: 66.66%; }
.span-9 { width: 75%; }
.span-10 { width: 83.33%; }
.span-11 { width: 91.66%; }
.span-12 { width: 100%; }

.offset-1 { margin-left: 8.33%; }
.offset-2 { margin-left: 16.66%; }
.offset-3 { margin-left: 25%; }
.offset-4 { margin-left: 33.33%; }
.offset-5 { margin-left: 41.66%; }
.offset-6 { margin-left: 50%; }
.offset-7 { margin-left: 58.33%; }
.offset-8 { margin-left: 66.66%; }
.offset-9 { margin-left: 75%; }
.offset-10 { margin-left: 83.33%; }
.offset-11 { margin-left: 91.66%; }
.offset-12 { margin-left: 100%; }

/* RESPONSIVENESS */

/* Large desktop */
@media (min-width: 1200px) {
    .table.fixed  {
        & > .row {
            width: 1170px;
        }
    }
}

/* Default */
@media (min-width: 980px) and (max-width: 1199px) {
    .table.fixed {
        & > .row {
            width: 960px;
        }
    }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
    .table.fixed { 
        & > .row {
            width: 724px;
        }
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    .table.fixed {
        &> .row {
            width: 100%;
        }
    }
}

/* Landscape phones and down */
@media (max-width: 480px) {
    .table.fixed {
        & > .row {
            width: 100%;
        }
    }
}
