.error-wrapper {
  background: #FFFFFF;
  padding: 40px 20px;
}
.error-wrapper h1 {
  color: #777777;
  font-weight: normal;
  margin-bottom: 0;
  font-size: 60px;
  font-family: 'Open Sans', sans-serif;
}
.error-wrapper .title h4 {
  color: #777777;
  margin-top: 0;
  font-weight: bold;
  margin: 1.5rem 0 2.5rem;
  font-size: 30px;
}
.error-wrapper .text-error-code {
  color: #C3C3C3;
  font-size: 30px;
  margin-bottom: 2rem;
}
.error-wrapper .button-wrapper input {
  display: inline-block;
  text-align: center;
  background: #fff;
  padding: 5px 30px;
  color: #777;
  font-size: 24px;
  box-shadow: none;
  border: 1px solid #777;
  margin-bottom: 15px;
}
.error-wrapper .button-wrapper input:hover {
  background: #777;
  color: #fff;
}
.waiting-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.overlay-content {
  top: 50%;
  left: 50%;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  display: table;
}
.overlay-content span {
  text-align: center;
  font-size: 15pt;
  color: white;
  text-wrap: none;
  white-space: nowrap;
  padding-left: 10px;
  vertical-align: central;
  display: table-cell;
}
.spinner {
  display: table-cell;
  width: 40px;
  height: 40px;
  background-color: #fff;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.846;
  color: #333333;
}
.hide {
  visibility: collapse;
}
#main-menu.navbar-collapse .zone.zone-navigation .dropdown-menu .nav-item .nav-link {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212121;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.searchWrap a.btn.btn-primary.btn-sm {
  text-align: center;
  width: 30px;
  padding-left: 0;
  padding-right: 0;
}
.searchWrap a.all-list {
  padding: 5px 12px;
  min-width: 125px;
}
#advance_search_panel input {
  border: 1px solid #eee;
  box-shadow: none;
  padding: 0 5px;
  background: #fff;
  -webkit-appearance: default-button;
  -moz-appearance: default-button;
  appearance: default-button;
}
#advance_search_panel input:focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  -ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.formSearch .btn-center {
  display: flex;
  align-items: center;
}
.formSearch .btnWrapper {
  padding-left: 0;
  padding-right: 0;
  float: left;
}
.formSearch .btnWrapper button {
  color: #fff;
  box-shadow: none;
  font-weight: bold;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#86b1e7+0,3e6ba4+100 */
  background: #86b1e7;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2YjFlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTZiYTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86b1e7), color-stop(100%, #3e6ba4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #86b1e7 0%, #3e6ba4 100%);
  /* W3C */
  border: 1px solid #c5c5c5;
  margin: 0 0.5rem 0 0;
}
.formSearch .btnWrapper button:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3e6ba4+0,86b1e7+100 */
  background: #3e6ba4;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNmJhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NmIxZTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e6ba4), color-stop(100%, #86b1e7));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3e6ba4 0%, #86b1e7 100%);
  /* W3C */
}
.formSearch .btnWrapper:last-child {
  margin-right: 0;
}
.formSearch .btnWrapper button.btn-advance-search.collapsed {
  color: #fff;
  box-shadow: none;
  font-weight: bold;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#86b1e7+0,3e6ba4+100 */
  background: #86b1e7;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2YjFlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTZiYTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86b1e7), color-stop(100%, #3e6ba4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #86b1e7 0%, #3e6ba4 100%);
  /* W3C */
  border: 1px solid #c5c5c5;
}
.formSearch .btnWrapper button.btn-advance-search.collapsed:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3e6ba4+0,86b1e7+100 */
  background: #3e6ba4;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNmJhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NmIxZTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e6ba4), color-stop(100%, #86b1e7));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3e6ba4 0%, #86b1e7 100%);
  /* W3C */
}
.formSearch .btnWrapper button.btn-advance-search.collapsed:before {
  content: "\f0dd";
  position: absolute;
  top: 10px;
  font-size: 12px;
  right: 4px;
  color: #fff;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.formSearch .btnWrapper button.btn-advance-search {
  color: #fff;
  box-shadow: none;
  font-weight: bold;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#86b1e7+0,3e6ba4+100 */
  background: #86b1e7;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2YjFlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTZiYTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86b1e7), color-stop(100%, #3e6ba4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #86b1e7 0%, #3e6ba4 100%);
  /* W3C */
  border: 1px solid #c5c5c5;
}
.formSearch .btnWrapper button.btn-advance-search:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3e6ba4+0,86b1e7+100 */
  background: #3e6ba4;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNmJhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NmIxZTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e6ba4), color-stop(100%, #86b1e7));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3e6ba4 0%, #86b1e7 100%);
  /* W3C */
}
.formSearch .btnWrapper button.btn-advance-search:before {
  content: "\f0de";
  position: absolute;
  top: 10px;
  font-size: 12px;
  right: 4px;
  color: #fff;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.formSearch .form-group {
  margin-bottom: 0;
}
.formSearch .col-lg-12 {
  position: relative;
}
.formSearch span {
  line-height: 0.9;
  display: inline-block;
  padding-top: 2px;
}
.btn-search-buyer-guide {
  line-height: 0;
  padding: 0 8px;
  height: 31px;
  line-height: 31px;
}
.btn-search-buyer-guide span.glyphicon.glyphicon-search {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.bx-wrapper {
  max-width: 745px!important;
  margin: 0 auto 23px!important;
}
.bx-wrapper .bx-viewport {
  left: 0!important;
}
.dropdown.dropdownFilter {
  display: inline-block;
  position: static;
}
.dropdown-menu.form-horizontal.filterInner {
  float: none;
  max-width: 100%!important;
  width: 75%;
  padding: 15px;
  left: 15px;
}
.dropdown-menu.form-horizontal.filterInner input {
  margin-right: 5px!important;
  margin-left: 0!important;
}
.dropdown-menu.form-horizontal.filterInner .checkbox label {
  padding-left: 0;
}
.title-wrapper {
  padding: 0 0 20px;
  color: #2d4e79;
}
.title-wrapper h2 {
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: #2d4e79;
}
/*button
****************************************************************/
button.btn,
a.btn,
input[type="submit"].btn {
  color: #fff;
  box-shadow: none;
  font-weight: bold;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#86b1e7+0,3e6ba4+100 */
  background: #86b1e7;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2YjFlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTZiYTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86b1e7), color-stop(100%, #3e6ba4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #86b1e7 0%, #3e6ba4 100%);
  /* W3C */
  border: 1px solid #c5c5c5;
}
button.btn:hover,
a.btn:hover,
input[type="submit"].btn:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3e6ba4+0,86b1e7+100 */
  background: #3e6ba4;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNmJhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NmIxZTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e6ba4), color-stop(100%, #86b1e7));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3e6ba4 0%, #86b1e7 100%);
  /* W3C */
}
.menuIcon {
  height: 32px;
  display: block;
  margin: 10px auto 0;
}
select,
select.form-control {
  appearance: menulist;
}
.table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.table > .row {
  width: 100%;
}
.row,
.cell {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.table.fixed > .row {
  margin-left: auto;
  margin-right: auto;
}
.row {
  margin: 0 0 20px 0;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.row > .cell:last-of-type {
  padding-right: 0;
}
.row > .cell:first-of-type {
  padding-left: 0;
}
.cell {
  display: block;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
  min-height: 1px;
}
/* Opt-in outside padding */
.row-pad {
  padding: 20px 0 20px 20px;
}
.row-pad .cell:last-of-type {
  padding-right: 20px;
}
.span-1-1 {
  width: 100%;
}
.span-1-2 {
  width: 50%;
}
.span-1-3 {
  width: 33.33%;
}
.span-1-4 {
  width: 25%;
}
.span-1-5 {
  width: 20%;
}
.span-1-6 {
  width: 16.66%;
}
.span-1-7 {
  width: 14.28%;
}
.span-1-8 {
  width: 12.5%;
}
.span-1-9 {
  width: 11.11%;
}
.span-1-10 {
  width: 10%;
}
.span-1-11 {
  width: 9.09%;
}
.span-1-12 {
  width: 8.33%;
}
.span-2-3 {
  width: 66.66%;
}
.span-2-4 {
  width: 50%;
}
.span-2-5 {
  width: 40%;
}
.span-2-6 {
  width: 33.33%;
}
.span-2-8 {
  width: 25%;
}
.span-2-10 {
  width: 20%;
}
.span-2-12 {
  width: 16.66%;
}
.span-3-4 {
  width: 75%;
}
.span-3-5 {
  width: 60%;
}
.span-3-6 {
  width: 50%;
}
.span-3-8 {
  width: 37.5%;
}
.span-3-10 {
  width: 33.33%;
}
.span-3-12 {
  width: 25%;
}
.span-4-5 {
  width: 80%;
}
.span-4-6 {
  width: 66.66%;
}
.span-4-8 {
  width: 50%;
}
.span-4-10 {
  width: 40%;
}
.span-4-12 {
  width: 33.33%;
}
.span-5-6 {
  width: 83.33%;
}
.span-5-8 {
  width: 75%;
}
.span-5-10 {
  width: 50%;
}
.span-5-12 {
  width: 41.66%;
}
.span-6-8 {
  width: 75%;
}
.span-6-10 {
  width: 60%;
}
.span-6-12 {
  width: 50%;
}
.span-7-8 {
  width: 87.5%;
}
.span-7-10 {
  width: 70%;
}
.span-7-12 {
  width: 58.33%;
}
.span-8-10 {
  width: 80%;
}
.span-8-12 {
  width: 66.66%;
}
.span-9-10 {
  width: 90%;
}
.span-9-12 {
  width: 75%;
}
.span-10-12 {
  width: 83.33%;
}
.span-11-12 {
  width: 91.66%;
}
.layout-content-image,
.layout-content-vector {
  display: block;
  max-width: 100%;
  height: auto;
}
/* Bootstrap compatible spans */
.span-1 {
  width: 8.33%;
}
.span-2 {
  width: 16.66%;
}
.span-3 {
  width: 25%;
}
.span-4 {
  width: 33.33%;
}
.span-5 {
  width: 41.66%;
}
.span-6 {
  width: 50%;
}
.span-7 {
  width: 58.33%;
}
.span-8 {
  width: 66.66%;
}
.span-9 {
  width: 75%;
}
.span-10 {
  width: 83.33%;
}
.span-11 {
  width: 91.66%;
}
.span-12 {
  width: 100%;
}
.offset-1 {
  margin-left: 8.33%;
}
.offset-2 {
  margin-left: 16.66%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33%;
}
.offset-5 {
  margin-left: 41.66%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33%;
}
.offset-8 {
  margin-left: 66.66%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33%;
}
.offset-11 {
  margin-left: 91.66%;
}
.offset-12 {
  margin-left: 100%;
}
/* RESPONSIVENESS */
/* Large desktop */
@media (min-width: 1200px) {
  .table.fixed > .row {
    width: 1170px;
  }
}
/* Default */
@media (min-width: 980px) and (max-width: 1199px) {
  .table.fixed > .row {
    width: 960px;
  }
}
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .table.fixed > .row {
    width: 724px;
  }
}
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .table.fixed > .row {
    width: 100%;
  }
}
/* Landscape phones and down */
@media (max-width: 480px) {
  .table.fixed > .row {
    width: 100%;
  }
}
/*This style for landing page */
#landing-page {
  padding: 0 30px;
  color: #333333;
}
#landing-page img {
  margin: 0 auto 15px;
  text-align: center;
  width: 100%;
}
#landing-page .how-todo {
  padding: 30px;
  background: #eeeeee;
  border-radius: 8px;
}
#landing-page .how-todo h3 {
  color: #3C4E5E;
  font-weight: bold;
}
#landing-page .how-todo ul {
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #3C4E5D;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#landing-page .how-todo ul li {
  color: #3C4E5D;
  list-style: none;
}
#landing-page-guide {
  margin-top: 20px;
}
#landing-page-guide h1 {
  text-align: center;
}
#landing-page-guide ul {
  padding: 30px;
  background: #eeeeee;
  border-radius: 8px;
}
#landing-page-guide ul ul {
  background: none;
  padding: 15px;
}
.style-button-default {
  color: #fff;
  box-shadow: none;
  font-weight: bold;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#86b1e7+0,3e6ba4+100 */
  background: #86b1e7;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2YjFlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTZiYTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86b1e7), color-stop(100%, #3e6ba4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #86b1e7 0%, #3e6ba4 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #86b1e7 0%, #3e6ba4 100%);
  /* W3C */
  border: 1px solid #c5c5c5;
}
.style-button-default:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3e6ba4+0,86b1e7+100 */
  background: #3e6ba4;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNmJhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NmIxZTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e6ba4), color-stop(100%, #86b1e7));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #3e6ba4 0%, #86b1e7 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #3e6ba4 0%, #86b1e7 100%);
  /* W3C */
}
