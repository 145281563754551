﻿/*This style for landing page */
@import "variables.less";

#landing-page {
    padding: 0 30px;
    color: @text-color;

    img {
        margin: 0 auto 15px;
        text-align: center;
        width: 100%;
    }

    .how-todo {
        padding: 30px;
        background: @jumbotron-bg;
        border-radius: 8px;

        h3 {
            color: #3C4E5E;
            font-weight: bold;
        }

        ul {
            padding: 0;
            list-style: none;
            border-bottom: 1px solid #3C4E5D;
            margin-bottom: 15px;
            padding-bottom: 15px;

            li {
                color: #3C4E5D;
                list-style: none;
            }
        }
    }
}

#landing-page-guide {
    margin-top: 20px;

    h1 {
        text-align: center;
    }

    ul {
        padding: 30px;
        background: @jumbotron-bg;
        border-radius: 8px;
        
        ul {
            background: none;
            padding: 15px;
        }
    }
}