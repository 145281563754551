﻿.waiting-overlay{
    position:fixed;
    top:0;
    left:0;
    background-color: black;
    opacity:0.5;
    width:100%;
    height:100%;
}
.overlay-content{
    top:50%;
    left:50%;
    margin-top:0;
    margin-bottom:0;
    position:absolute;
    transform: translate(-50%, -50%);
    display:table;

    span{

        text-align:center;
        font-size:15pt;
        color:white;
        text-wrap:none;
        white-space:nowrap;
        padding-left:10px;
        vertical-align:central;
        display:table-cell;
    }
}
.spinner {
    display:table-cell;
    width: 40px;
    height: 40px;
    background-color: #fff;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
