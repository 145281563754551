﻿.error-wrapper {
    background: #FFFFFF;
    padding: 40px 20px;

    h1 {
        color: #777777;
        font-weight: normal;
        margin-bottom: 0;
        font-size: 60px;
        font-family: 'Open Sans', sans-serif;
    }

    .title {

        h4 {
            color: #777777;
            margin-top: 0;
            font-weight: bold;
            margin: 1.5rem 0 2.5rem;
            font-size: 30px;
        }
    }

    .text-error-code {
        color: #C3C3C3;
        font-size: 30px;
        margin-bottom: 2rem;
    }


    .button-wrapper {

        input {
            display: inline-block;
            text-align: center;
            background: #fff;
            padding: 5px 30px;
            color: #777;
            font-size: 24px;
            box-shadow: none;
            border: 1px solid #777;
            margin-bottom: 15px;

            &:hover {
                background: #777;
                color: #fff;
            }
        }
    }
}
