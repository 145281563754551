﻿@import "variables.less";
@import "mixin.less";

body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 1.846;
    color: @text-color;
}
// use for hide same as collapse bootstrap class
.hide{
    visibility:collapse;
}

//Navigation
#main-menu.navbar-collapse {
    .zone.zone-navigation {
        .dropdown-menu {
            .nav-item {
                .nav-link {
                    display: block;
                    width: 100%;
                    padding: 0.25rem 1.5rem;
                    clear: both;
                    font-weight: 400;
                    color: #212121;
                    text-align: inherit;
                    white-space: nowrap;
                    background-color: transparent;
                    border: 0;
                }
            }
        }
    }
}

.searchWrap {
    a.btn.btn-primary.btn-sm {
        text-align: center;
        width: 30px;
        padding-left: 0;
        padding-right: 0;
    }

    a.all-list {
        padding: 5px 12px;
        min-width: 125px;
    }
}

#advance_search_panel {
    input {
        border: 1px solid #eee;
        box-shadow: none;
        padding: 0 5px;
        background: #fff;
        -webkit-appearance: default-button;
        -moz-appearance: default-button;
        appearance: default-button;

        &:focus {
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            -moz-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            -ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        }
    }
}

.formSearch {
    .btn-center {
        display: flex;
        align-items: center;
    }

    .btnWrapper {
        padding-left: 0;
        padding-right: 0;
        float: left;

        button {
            .style-button-default;
            margin: 0 0.5rem 0 0;
        }

        &:last-child {
            margin-right: 0;
        }

        button.btn-advance-search.collapsed {
            .style-button-default;

            &:before {
                .icon-arrow ("\f0dd");
            }
        }

        button.btn-advance-search {
            .style-button-default;

            &:before {
                .icon-arrow ("\f0de");
            }
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    .col-lg-12 {
        position: relative;
    }

    span {
        line-height: .9;
        display: inline-block;
        padding-top: 2px;
    }
}

.btn-search-buyer-guide {
    line-height: 0;
    padding: 0 8px;
    height: 31px;
    line-height: 31px;

    span.glyphicon.glyphicon-search {
        font-size: 20px;
        position: relative;
        top: 3px;
    }
}

.bx-wrapper {
    max-width: 745px!important;
    margin: 0 auto 23px!important;

    .bx-viewport {
        left: 0!important;
    }
}

.dropdown.dropdownFilter {
    display: inline-block;
    position: static;
}

.dropdown-menu.form-horizontal.filterInner {
    float: none;
    max-width: 100%!important;
    width: 75%;
    padding: 15px;
    left: 15px;

    input {
        margin-right: 5px!important;
        margin-left: 0!important;
    }

    .checkbox {
        label {
            padding-left: 0;
        }
    }
}

.title-wrapper {
    padding: 0 0 20px;
    color: #2d4e79;

    h2 {
        font-weight: bold;
        margin: 0;
        padding: 0;
        font-size: 24px;
        color: #2d4e79;
    }
}
/*button
****************************************************************/
button.btn,
a.btn,
input[type="submit"].btn {
    .style-button-default;
}

.menuIcon {
    height: 32px;
    display: block;
    margin: 10px auto 0;
}

select, select.form-control {
    appearance: menulist;
}