﻿.style-button-default {
    color: #fff;
    box-shadow: none;
    font-weight: bold;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#86b1e7+0,3e6ba4+100 */
    background: rgb(134,177,231); /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg2YjFlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTZiYTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(134,177,231,1) 0%, rgba(62,107,164,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(134,177,231,1)), color-stop(100%,rgba(62,107,164,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(134,177,231,1) 0%,rgba(62,107,164,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(134,177,231,1) 0%,rgba(62,107,164,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(134,177,231,1) 0%,rgba(62,107,164,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(134,177,231,1) 0%,rgba(62,107,164,1) 100%); /* W3C */
    border: 1px solid #c5c5c5;

    &:hover {
        color: #fff;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3e6ba4+0,86b1e7+100 */
        background: rgb(62,107,164); /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNmJhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NmIxZTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,  rgba(62,107,164,1) 0%, rgba(134,177,231,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(62,107,164,1)), color-stop(100%,rgba(134,177,231,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  rgba(62,107,164,1) 0%,rgba(134,177,231,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  rgba(62,107,164,1) 0%,rgba(134,177,231,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  rgba(62,107,164,1) 0%,rgba(134,177,231,1) 100%); /* IE10+ */
        background: linear-gradient(to bottom,  rgba(62,107,164,1) 0%,rgba(134,177,231,1) 100%); /* W3C */
    }
}


.icon-arrow (@icon) {
    position: absolute;
    content: @icon;
    position: absolute;
    top: 10px;
    font-size: 12px;
    right: 4px;
    color: #fff;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}            
